import axios from "axios";
// import store from "./store/index";

function getCookie() {
  let name = "token" + "=";
  let ca = document.cookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
// if (getCookie() == "") {
//   window.location.href = `${store.state.surl}/SignIn`;
// }
const token = getCookie();
axios.defaults.headers.common["Authorization"] = "bearer " + token;
axios.defaults.baseURL = "http://demo-backend.hesabtech.com";
// axios.defaults.baseURL = "http://92.205.190.86:3000";
// axios.defaults.baseURL = "http://localhost:3000";
